<script>
export default {
  name: 'GetTitleCahier',
  props: {
    meta: { required: true, type: String },
    titre: { required: false, type: String, default: '' },
    fromHandle: { required: false, type: Boolean, default: false },
    listView: { required: false, type: Boolean, default: false },
    presentation: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      boutiqueItem: null,
      loading: false
    }
  },
  apollo: {
    boutiqueItem: {
      query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
      variables () {
        return {
          attrs: { slug: this.meta }
        }
      },
      update (data) {
        this.loading = false
        return data.boutiqueItemBy
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  computed: {
    picto () {
      if (this.boutiqueItem && this.boutiqueItem.images && this.boutiqueItem.images.length > 0) {
        const picto = this.boutiqueItem.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-pictogramme'))
        if (picto) {
          return picto.medium_image
        }
      }
      return null
    }
  }
}
</script>

<template lang="pug">
  .get-title-cahier.w-100(:class='{"list-view" : listView}')
    //- pre LISTVIEW {{listView}}
    template(v-if='listView')
      v-list-item-avatar()
        img(:src='picto', type='svg')
      v-list-item-content
        v-list-item-title(v-if='titre') {{titre}}
        v-list-item-subtitle {{boutiqueItem.titre}}
    template(v-else-if='!fromHandle')
      v-avatar.mb2.relative(v-if='picto && !presentation', size='45', color='transparent')
        div.color-fallback()
        img.avatar-image(:src='picto', type='svg')
      .produit-title.relative(v-if='titre') {{titre}}
      v-divider.relative.w-100(color='white')
      //- pre {{picto}}
      //- pre {{boutiqueItem}}
      div.w-100.mt3(v-if='loading')
        v-skeleton-loader.mx-auto(type='text', max-width='300')
      div.f7.white--text.relative.mt2(v-else-if='boutiqueItem') {{boutiqueItem.titre}}
    template(v-else)
      div.w-100.mt3(v-if='loading')
        v-skeleton-loader.mx-auto(type='text', max-width='300')
      v-list-item-title(v-else-if='boutiqueItem') {{boutiqueItem.titre}}
</template>

<style lang='sass' scoped>
  .get-title-cahier
    &.list-view
      display: flex
</style>
