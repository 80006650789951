<script>
import CreateBiblioListe from '@/components/dialogs/actions/createBiblioListe.vue'
import GetTitleCahier from '@/components/lectures/getTitleCahier.vue'

import boutiqueFavorite from '@/mixins/boutiqueFavorite.js'
import listContent from '@/mixins/listContent.js'
import BoutiqueArchive from '@/mixins/boutiqueArchive.js'
import openLink from '@/mixins/openLink.js'
import draggable from 'vuedraggable'

export default {
  name: 'ContentHandleList',
  props: {
    origin: { required: true, type: String },
    produits: { required: false, type: Array, default: () => [] },
    loadingExpiredOrArchived: { required: false, type: Boolean, default: false }
  },
  components: {
    CreateBiblioListe,
    GetTitleCahier,
    draggable
  },
  mixins: [boutiqueFavorite, BoutiqueArchive, listContent, openLink],
  watch: {
  },
  computed: {
    showAjoutCode: {
      set (val) { this.$store.commit('Dialogs/setAjoutCode', val) },
      get () { return this.$store.state.Dialogs.showAjoutCode }
    },
    showOnlyFavsBiblio: {
      get () { return this.$store.state.Bibliotheque.showOnlyFavs },
      set (val) { this.$store.commit('Bibliotheque/setShowOnlyFavs', val) }
    },
    showOnlyFavsExercices: {
      get () { return this.$store.state.Exercices.showOnlyFavs },
      set (val) { this.$store.commit('Exercices/setShowOnlyFavs', val) }
    }
  },
  data () {
    return {
      showArchive: false,
      showExpired: false,
      filterList: null,
      selectedCategorie: { title: this.$t('action.tout-voir'), value: '0' },
      newListSuccess: false,
      dialogListeCreation: false,
      currentSelectedList: null,
      dialogListeSwitch: false,
      selectedProduit: null,
      listView: false,
      search: null,
      selectedFilter: null,
      filterTypes: [
        { title: this.$t('action.ordre-croissant'), value: 'alphaUp' },
        { title: this.$t('action.ordre-decroissant'), value: 'alphaDown' },
        { title: this.$t('action.ordre-recent'), value: 'dateUp' },
        { title: this.$t('action.ordre-vieux'), value: 'dateDown' }
      ]
    }
  },
  methods: {
    openLink (produit) {
      if (produit.contenu?.tableauxBlancs) {
        let temp = 'https://boutique.dokoma.com/fr/produits/' + produit.contenu?.tableauxBlancs[0].nuageItem.boutiqueProduits[0].slug + '/' + produit.contenu?.tableauxBlancs[0].nuageItem.boutiqueItem.slug
        this.writeTokenInCookiesAndOpenLink(temp)
      } else {
        this.writeTokenInCookiesAndOpenLink(produit.boutiqueLink)
      }
    },
    deleteListe (listName) {
      console.log('TODO UI DELETE LISTE')
    },
    toggleListCreation (produit) {
      this.dialogListeCreation = !this.dialogListeCreation
    },
    transferToListe (newList, oldList, produit) {
      this.selectedProduit = produit
      this.currentSelectedList = oldList
      this.dialogListeSwitch = false
      const targetList = this.listesBiblio.findIndex(elem => elem.titre === newList)
      let previousList = null
      this.$store.commit('Bibliotheque/setListesBiblioItems', { target: targetList, produit: this.selectedProduit })
      if (this.currentSelectedList !== 'default' && this.currentSelectedList !== newList) {
        previousList = this.listesBiblio.findIndex(elem => elem.titre === this.currentSelectedList)
        this.$store.commit('Bibliotheque/setListesBiblioItems', { target: previousList, produit: this.selectedProduit })
      }
    },
    toggleListView () {
      this.listView = !this.listView
      this.$emit('togglelistView', this.listView)
    },
    updateSearch (val) {
      this.$emit('updateSearch', val)
    },
    isActive (produit) {
      let active = false
      // console.log('prod', produit)
      if (this.origin === 'lectures') {
        if (produit.identifiant) {
          if (this.$route.query.document && this.$route.query.document === produit.identifiant) {
            active = true
          }
        }
      } else {
        if (produit.favori) {
          if (this.$route.query.document && this.$route.query.document === produit.favori.slug) {
            active = true
          }
        } else if (this.$route.query.document && this.$route.query.document === produit.slug) {
          active = true
        }
      }
      return active
    },
    getRoute (produit) {
      let route = { name: this.origin, query: null }
      if (this.origin === 'bibliotheque') {
        if (produit.favori) {
          route.query = { document: produit.favori.slug, nuage: true }
        } else if (produit.__typename === 'BoutiqueItem') {
          route.query = { document: produit.slug, nuage: true }
        } else {
          route.query = { document: produit.slug }
        }
      } else if (this.origin === 'lectures') {
        route.query = { document: produit.identifiant ? produit.identifiant : produit.batch }
        // :to="{ name: 'lectures', query: { document: produit.identifiant ? produit.identifiant : produit.batch } }"
      } else if (this.origin === 'exercices') {
        if (produit.__typename === 'BoutiqueItem') {
          route.query = { document: produit.slug, nuage: true }
        } else {
          route.query = { document: 'classe-a-la-maison' }
        }
      } else if (this.origin === 'devoirs') {
        route.name = 'devoirDetail'
        if (produit && produit.identifiant) {
          route.params = { id: produit.identifiant }
          route.query = { identifiant: true }
        } else if (produit && produit.batch) {
          route.params = { id: produit.batch }
          route.query = { batch: true }
        }
      } else if (this.origin === 'tableauxBlanc') {
        route.name = 'tableauDetail___'
        route.params = { id: produit.identifiant }
      } else if (this.origin === 'tableauxBlancAchats') {
        route.name = 'tableauxAchats___'
        route.query = { t: produit.slug, nuage: true }
      }
      return route
    }
  }
}
</script>

<template lang="pug">
.content-handle-list
  //- pre {{activeList}}
  //- À rebrancher quand on aura la requete
  //- create-biblio-liste(:dialogListCreation='dialogListeCreation', @createList='createListe', @closeDialog='toggleListCreation')
  //- pre {{activeList}}

  div.produits-navigation__mobile(v-if='$store.state.App.mobileTemplate && !$route.query.document')
    .navigation-title {{activeList.name}}
    template(v-if='origin === "bibliotheque" || origin === "exercices"')
      v-btn.mt3(color='primary', @click='showAjoutCode = true', rounded) {{$t('action.ajouter-code')}}
    v-text-field(light, dense, :placeholder='$t("action.trouver-docu")', rounded, hide-details, v-model='search', clearable, solo, depressed, flat, @input='updateSearch', background-color='white')
    v-select(light, dense, :placeholder='$t("action.ordonner-docu")', item-color='secondary', rounded, hide-details, v-model='biblioOrder', :items='filterTypes', item-text='title', item-value='value', clearable, outlined, depressed, flat, style='margin-top:10px;', color='secondary')
  perfect-scrollbar.produits-navigation(v-else, :style="{backgroundColor: $store.state.Preferences.darkMode ? 'var(--v-light-base)' : 'white'}", :options='{wheelPropagation: false}')
    .navigation__header
      .navigation-title {{activeList.name}}
    .navigation__actions
      template(v-if='origin === "bibliotheque" || origin === "exercices"')
        v-btn.mb3(color='primary', @click='showAjoutCode = true', rounded) {{$t('action.ajouter-code')}}
      template(v-else-if='origin === "lectures"')
        span.mb3.center {{ $tc('messages.liste-actif', activeList.produits.length , { nb: activeList.produits.length })}}
        v-btn(rounded, small, block, depressed, color='secondary', :to='{ name: "bibliotheque___" + $i18n.locale }') {{$t('action.voir-biblio')}}
        v-btn.mt1.mb3(rounded, small, block, depressed, color='primary', :to='{ name: "lectureEdition___" + $i18n.locale }') {{$t('action.creer-liste')}}
      div.flex.items-center.mb2(v-if='origin != "devoirs"')
        v-text-field(light, dense, :placeholder='$t("action.trouver-docu")', rounded, solo, hide-details, v-model='search', clearable, outlined, depressed, flat, @input='updateSearch')
        //- v-btn.ml2(fab, small, depressed, color='secondary', @click='toggleListView')
          font-awesome-icon.f5(:icon="['fad', listView ? 'th-large' : 'list']")
      v-select(v-if='origin != "devoirs"', light, dense, :placeholder='$t("action.ordonner-docu")', rounded, solo, hide-details, v-model='biblioOrder', :items='filterTypes', item-text='title', item-value='value', clearable, outlined, depressed, flat)
      template(v-if='origin === "bibliotheque"')
        v-checkbox(v-model="showOnlyFavsBiblio" color="primary" hide-details)
          template(v-slot:label)
            span {{ $t('lecture.display-only-favs')}}
            v-btn(icon, :color='showOnlyFavsBiblio ? "error" : ""')
              font-awesome-icon.ml-2(:icon="[showOnlyFavsBiblio ? 'fas' : 'far', 'heart']", style='font-size: 20px')
      template(v-else-if="origin === 'exercices'")
        v-checkbox(v-model="showOnlyFavsExercices" color="primary" hide-details)
          template(v-slot:label)
            span {{ $t('lecture.display-only-favs')}}
            v-btn(icon, :color='showOnlyFavsExercices ? "error" : ""')
              font-awesome-icon.ml-2(:icon="[showOnlyFavsExercices ? 'fas' : 'far', 'heart']", style='font-size: 20px')
    .navigation__content
      v-list(dense, nav, light)
        template(v-for='(liste, ind) in activeList.listes')
          v-list-group.pa1(v-model='liste.active')
            template(v-slot:activator)
              font-awesome-icon.f6(v-if='liste.icon', :icon="['fas', liste.icon]")
              v-list-item-content
                v-list-item-title(v-if='liste.titre') {{liste.titre}} {{(liste.items && liste.items.length > 0) ? '(' + liste.items.length + ')' : ''}}
                get-title-cahier(v-else-if='origin === "lectures"', :meta='liste.document', :fromHandle='true')
                v-list-item-title(v-else) {{$t('menu.liste-sans-nom')}}
            draggable(
              :id="'liste-' + ind"
              :list="liste.items"
              class="list-group"
              draggable=".item"
              group="a",
              ghost-class='ghost'
              :disabled='!liste.draggable'
              animation='150'
            )
              transition-group(type="transition")
                template(v-if='liste.items && liste.items.length')
                  template(v-for='(produit, index) in liste.items')
                    v-list-item.list-group-item.item(:key='"base-" + index', text, :to='getRoute(produit)', color='secondary', :class='{"active-element" : isActive(produit) }')
                      v-list-item-content()
                        v-list-item-title
                          span(v-if='produit.titre') {{produit.titre}}
                          span(v-else-if='produit.favori && produit.favori.titre') {{produit.favori.titre}}
                          span(v-else) {{$t('menu.nom-indisponible')}}
                      v-list-item-action
                        v-menu(offset-y)
                          template(v-slot:activator='{ on }')
                            v-btn(icon,x-small, @click.prevent.stop='', v-on='on')
                              font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
                          v-card
                            v-list(dense, nav)
                              v-list-item(v-if='liste.titre !== "Favoris" && liste.titre !== "Favorites"', @click='transferToListe(liste.titre, liste.titre, produit)')
                                v-list-item-title {{$t('action.retirer')}}
                              v-list-item(@click='toggleFavBoutique(produit)')
                                v-list-item-title {{liste.titre === 'Favoris' || liste.titre === 'Favorites' ? $t('action.remove-fav') : $t('action.add-fav')}}
                              v-list-group(@click.stop='', color='secondary')
                                template(v-slot:activator)
                                  v-list-item-content
                                    v-list-item-title {{$t('action.deplacer')}}
                                template(v-for='(lis, index) in listesBiblio')
                                  v-list-item(v-if='lis.titre !== liste.titre', color='red', @click='transferToListe(lis.titre, liste.titre, produit)', :key='index')
                                    v-list-item-content
                                      v-list-item-title {{lis.titre}}
                template(v-else)
                  v-list-item(key='empty')
                    v-list-item-content
                      v-list-item-title {{$t('lecture.liste-vide')}}

        v-list-group.pa1(v-model='showArchive', v-if='(origin === "bibliotheque" ||  origin === "exercices")')
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title {{$t('action.archiver')}} ({{ loadingExpiredOrArchived ? $t('boite.loading') : produitsArchived.length }})
          draggable(
            id="liste-default"
            :list="produitsArchived"
            class="list-group"
            draggable=".item"
            group="a",
            ghost-class='ghost'
            animation='150'
          )
            transition-group(type="transition")
              template(v-if='produitsArchived && produitsArchived.length')
                v-list-item.list-group-item.item(:key='index', text, v-for='(produit, index) in produitsArchived', :to="getRoute(produit.boutiqueItem)", color='secondary', :class='{"active-element" : ($route.query.document && produit.boutiqueItem && produit.boutiqueItem.slug === $route.query.document)}')
                  v-list-item-content
                    v-list-item-title
                      span(v-if='produit.boutiqueItem.titre') {{produit.boutiqueItem.titre}}
                      span(v-else-if='produit.boutiqueItem.favori && produit.boutiqueItem.favori.titre') {{produit.boutiqueItem.favori.titre}}
                      v-skeleton-loader(v-else, type='list-item')
                  v-list-item-action
                    v-menu(offset-y)
                      template(v-slot:activator='{ on }')
                        v-btn(icon,x-small, @click.prevent.stop='', v-on='on')
                          font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
                      v-card
                        v-list.py-2(dense, nav)
                          v-list-item(@click='toggleFavBoutique(produit.boutiqueItem)')
                            v-list-item-title {{$t('menu.ajout-favoris')}}
                          v-list-item(@click='desarchiveProduit(produit)')
                            v-list-item-title {{ $t('action.desarchiver-docu') }}
              template(v-else)
                v-list-item(key='empty-fallback')
                  v-list-item-content
                    v-list-item-title {{$t('menu.liste-vide')}}
        v-list-group.pa1(v-model='showExpired', v-if='(origin === "bibliotheque" ||  origin === "exercices") || origin === "devoirs" || origin === "tableauxBlanc" || origin === "tableauxBlancAchats"')
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title {{ $t('calendrier.expire')}} ({{ loadingExpiredOrArchived ? $t('boite.loading') : produitsExpired.length }})
          draggable(
            id="liste-expired"
            :list="produitsExpired"
            class="list-group"
            draggable=".item"
            group="a",
            ghost-class='ghost'
            animation='150'
          )
            transition-group(type="transition")
              template(v-if='produitsExpired && produitsExpired.length')
                v-list-item.list-group-item.item(:key='index', text, v-for='(produit, index) in produitsExpired', :to="getRoute(produit)", color='secondary', :class='{"active-element" : ($route.query.document && produit && produit.slug && produit.slug === $route.query.document)}')
                  v-list-item-content
                    v-list-item-title
                      span(v-if='produit.titre') {{produit.titre}}
                      span(v-else-if='produit.favori && produit.favori.titre') {{produit.favori.titre}}
                      v-skeleton-loader(v-else, type='list-item')
                  v-list-item-action.flex-row(v-if='(origin === "bibliotheque" ||  origin === "exercices" || origin === "tableauxBlanc" || origin === "tableauxBlancAchats")')
                    v-btn(@click="openLink(produit)" icon x-small v-tooltip.bottom="{ content: $t('boutique.order-again') }")
                      font-awesome-icon.f6(:icon="['fad', 'shopping-cart']")
                    v-menu(offset-y)
                      template(v-slot:activator='{ on }')
                        v-btn(icon,x-small, @click.prevent.stop='', v-on='on')
                          font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
                      v-card
                        v-list(dense, nav)
                          v-list-item(@click='toggleFavBoutique(produit)')
                            v-list-item-title {{$t('menu.ajout-favoris')}}
              template(v-else)
                v-list-item(key='empty-fallback')
                  v-list-item-content
                    v-list-item-title {{$t('menu.liste-vide')}}
</template>

<style lang="sass">
.navigation__content .v-list-item__icon.v-list-group__header__append-icon
  margin-left: 0!important
  min-width: fit-content
  width: 32px
</style>
