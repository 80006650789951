import { render, staticRenderFns } from "./getTitleCahier.vue?vue&type=template&id=665aef6a&scoped=true&lang=pug&"
import script from "./getTitleCahier.vue?vue&type=script&lang=js&"
export * from "./getTitleCahier.vue?vue&type=script&lang=js&"
import style0 from "./getTitleCahier.vue?vue&type=style&index=0&id=665aef6a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665aef6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAvatar,VDivider,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader})
