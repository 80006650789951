export default {
  computed: {
    isArchived () {
      let temp = false
      if (this.produit && this.produit.tagsVisibles && this.produit.tagsVisibles.find(t => t.id === 'nuage-item-etiquette-archiver')) {
        temp = true
      } else if (this.nuageExpire?.tagsVisibles?.find(t => t.id === 'nuage-item-etiquette-archiver')) {
        temp = true
      }
      return temp
    }
  },
  methods: {
    async archiveProduit () {
      const tags = this?.nuageItem?.tagsVisibles
      let archived = false
      if (this.isArchived) {
        const index = tags.findIndex(e => e.id === 'nuage-item-etiquette-archiver')
        if (index >= 0) {
          archived = true
          tags.splice(index, 1)
        } else {
          tags.push('nuage-item-etiquette-archiver')
        }
      } else {
        tags.push('nuage-item-etiquette-archiver')
      }
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/tagsNuageItems.gql'),
          variables: {
            item: this.nuageItem.id,
            tags
          }
        }).then(({ data }) => {
          this.confirmWord = null
          if (!data.tagsNuageItems || data.tagsNuageItems.errors) {
            throw Error('erreur tag nuage ', data.tagsNuageItems ? data.tagsNuageItems.errors : 'retourne null')
          } else {
            this.$emit(archived ? 'activate' : 'desactivate', this.nuageItem.id)
            this.$emit('refetch')
            this.showArchiveProduit = false
            this.$store.commit('App/setShowSnackbarGlobal', true)
            this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: this.$t('alerte.succes'), description: this.isArchived ? this.$t('action.ressource-active') : this.$t('action.ressource-archive') })
          }
        })
      } catch (e) {
        this.$store.commit('App/setShowSnackbarGlobal', true)
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: this.$t('alerte.erreur'), description: this.isArchived ? this.$t('alerte.erreur-activation-ressource') : this.$t('alerte.erreur-archive-ressource') })
        console.error(e)
      }
      this.loadingArchive = false
    },
    async desarchiveProduit (nuageItem) {
      const tags = nuageItem.tagsVisibles.map(tv => tv.id).filter(t => t !== 'nuage-item-etiquette-archiver')
      const variables = { tags, item: nuageItem.id }
      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/tagsNuageItems.gql'),
          variables
        })
        if (!data.tagsNuageItems || data.tagsNuageItems.errors) {
          this.confirmDesarchiveage(data.tagsNuageItems?.errors)
        } else {
          this.$emit('refetch')
          this.confirmDesarchiveage()
        }
      } catch (e) {
        this.confirmDesarchiveage(e)
      }
    },
    confirmDesarchiveage (error) {
      if (!error) {
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: this.$t('alerte.succes'), description: this.$t('action.ressource-active') })
        this.$store.commit('App/setShowSnackbarGlobal', true)
      } else {
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: this.$t('alerte.erreur'), description: this.$t('alerte.erreur-activation-ressource') })
        this.$store.commit('App/setShowSnackbarGlobal', true)
        console.error(error)
      }
    }
  }
}
