export default {
  data () {
    return {
    }
  },
  computed: {
    activeList () {
      let list = {}
      if (this.origin === 'bibliotheque') {
        list.name = this.$t('lecture.ma-biblio')
        list.listes = this.listesBiblio
      } else if (this.origin === 'lectures') {
        list.name = this.$t('lecture.mes-listes')
        list.listes = this.listesLecture
      } else if (this.origin === 'exercices') {
        list.name = this.$t('lecture.mes-exo')
        list.listes = this.listesLecture
      } else if (this.origin === 'devoirs') {
        list.name = this.$t('menu.gestion-devoirs')
      } else if (this.origin === 'tableauxBlanc') {
        list.name = this.$t('menu.mes-tableaux')
      } else if (this.origin === 'tableauxBlancAchats') {
        list.name = this.$t('menu.mes-tableaux')
      }
      list.produits = this.produitsFiltered
      return list
    },
    // Bibliotheque
    biblioOrder: {
      get () { return this.$store.state.Bibliotheque.biblioOrder },
      set (val) { this.$store.commit('Bibliotheque/setBiblioOrder', val) }
    },
    listesBiblio: {
      get () { return this.$store.state.Bibliotheque.listesBiblio },
      set (val) { this.$store.commit('Bibliotheque/setListesBiblio', val) }
    },
    // Exercices
    listesExercices () {
      const temp = []
      return temp
    },
    // Lectures
    listesLecture () {
      const temp = []
      this.produits.forEach(element => {
        if (this.search) {
          if (element.titre.toLowerCase().includes(this.search.toLowerCase()) && element && element.meta) {
            const metaParse = JSON.parse(element.meta)
            const findelem = temp.findIndex(e => e.document === metaParse.data.document)
            if (findelem === -1) {
              temp.push({ document: metaParse.data.document, items: [element] })
            } else {
              temp[findelem].items.push(element)
            }
          }
        } else {
          if (element && element.meta) {
            const metaParse = JSON.parse(element.meta)
            const findelem = temp.findIndex(e => e.document === metaParse.data.document)
            if (findelem === -1) {
              temp.push({ document: metaParse.data.document, items: [element] })
            } else {
              temp[findelem].items.push(element)
            }
          }
        }
      })
      return temp
    },
    produitsArchived () {
      let filtered = []
      if (this.origin === 'bibliotheque') {
        this.produits.forEach((el) => {
          if (el && el.tagsVisibles && el.tagsVisibles.find(t => t && t.id === 'nuage-item-etiquette-archiver')) {
            filtered.push(el)
          }
        })
      }
      return filtered.filter(x => !this.produitsExpired.includes(x.boutiqueItem))
    },
    produitsExpired () {
      let list = this.produits.filter(p => {
        if (p.contenu?.tableauxBlancs) {
          return p
        } else {
          return p.finAt !== null && this.$dayjs().isAfter(p.finAt)
        }
      }).map(e => {
        if (e.boutiqueItem) {
          const item = e.boutiqueItem
          item.boutiqueLink = this.generateBoutiqueLinkFromSlugs(e?.boutiqueProduits[0]?.slug, item.slug)
          return item
        } else {
          return e
        }
      })
      return this.deleteDiacritics(list)
    },
    produitsFiltered () {
      let filtered = []
      let favorites = this.$store.state.User.favoris
      if (this.origin === 'bibliotheque') {
        this.produits.forEach((el) => {
          if (el && el.tagsVisibles && !el.tagsVisibles.find(t => t && t.id === 'nuage-item-etiquette-archiver')) {
            if (el && el.boutiqueItem) {
              if (favorites) {
                let findIndex = favorites.findIndex((fav) => {
                  if (fav.favori) {
                    return fav.favori.id === el.boutiqueItem.id
                  } else {
                    return fav.id === el.id
                  }
                })
                if (findIndex < 0) {
                  filtered.push(el.boutiqueItem)
                }
              } else {
                filtered.push(el.boutiqueItem)
              }
            } else {
              if (favorites) {
                let findIndex = favorites.findIndex((fav) => {
                  if (fav.favori) {
                    return fav.favori.id === el.id
                  } else if (fav.meta) {
                    let data = JSON.parse(fav.meta)
                    if (data.data && data.data.id) {
                      return data.data.id === el.id
                    }
                  }
                })
                if (findIndex < 0) {
                  filtered.push(el)
                }
              } else {
                filtered.push(el)
              }
            }
          }
        })
      } else if (this.origin === 'lectures') {
        if (this.produits && this.produits.length > 0) {
          if (this.search) {
            return this.produits.filter((e) => {
              if (e.titre.toLowerCase().includes(this.search.toLowerCase())) {
                return e
              }
            })
          } else {
            return this.produits
          }
        } else {
          return []
        }
      } else if (this.origin === 'exercices') {
        let classemaison = {}
        // classemaison.id
        classemaison.id = 'test'
        classemaison.titre = 'Classe à la maison'
        // classemaison.descritpion = 'Ceci est une description'
        // classemaison
        filtered.push(classemaison)
        this.produits.forEach((el) => {
          if (el && el.boutiqueItem) {
            filtered.push(el.boutiqueItem)
          } else {
            filtered.push(el)
          }
          // else {
          //   if (favorites) {
          //     let findIndex = favorites.findIndex((fav) => {
          //       if (fav.favori) {
          //         return fav.favori.id === el.id
          //       } else if (fav.meta) {
          //         let data = JSON.parse(fav.meta)
          //         if (data.data && data.data.id) {
          //           return data.data.id === el.id
          //         }
          //       }
          //     })
          //     if (findIndex < 0) {
          //       filtered.push(el)
          //     }
          //   } else {
          //     filtered.push(el)
          //   }
          // }
        })
      }
      return filtered
    }
  },
  methods: {
    deleteDiacritics (val) {
      return val.sort((a, b) => a.titre.normalize('NFD').replace(/\p{Diacritic}/gu, '') !== b.titre.normalize('NFD').replace(/\p{Diacritic}/gu, '') ? a.titre.normalize('NFD').replace(/\p{Diacritic}/gu, '') < b.titre.normalize('NFD').replace(/\p{Diacritic}/gu, '') ? -1 : 1 : 0)
    },
    generateBoutiqueLinkFromSlugs (produitSlug, itemSlug) {
      return `https://boutique.dokoma.com/fr/produits/${produitSlug}/${itemSlug}`
    }
  }
}
