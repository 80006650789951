<script>
export default {
  name: 'createBiblioListe',
  data () {
    return {
      listName: null
    }
  },
  props: {
    dialogListCreation: { required: true, type: Boolean }
  },
  computed: {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    reset () {
      this.listName = null
    },
    clearDialog () {
      this.listName = null
      this.$emit('closeDialog')
    },
    createList () {
      this.showErrorPseudo = false
      this.$emit('createList', this.listName)
      this.clearDialog()
    }
  }
}
</script>

<template lang='pug'>
v-dialog(v-model='dialogListCreation', max-width='600', content-class='custom-dialog', persistent)

  v-card.primary.lighten-1()
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='clearDialog', @click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title Créer une nouvelle liste
    v-card-text.dark--text
      div.dialog-text
        div.b.f4.mb3 Tu aimes que tes documents soient bien rangés.
        div.f6 Insèrez un titre ici pour construire une nouvelle liste.
    v-card-text.relative
      v-text-field.code-input(placeholder='Le nom de ta liste ici!', solo, hide-details, v-model='listName', clearable, @keyup.enter='createList')
    v-divider.primary
    v-btn(@click='createList', color='secondary', depressed, block, x-large, dark, :disabled='!listName')
      span.f4 Envoyer
  //- v-card.primary.lighten-1(v-else-if='finish')
    v-card-text.dark--text
      div.b.f4.mb3 Demande envoyé
        v-divider.primary
    v-card-actions.primary
      v-spacer
      v-btn(@click='$emit("closeDialog")', @click.native='reset', outlined, color='secondary') Fermer
</template>
<style lang='sass'>

</style>
